$('.header_names').onclick(
  function() {
        $(this).next('.child_list').show();
      
  }
  , function() {
        $(this).next('.child_list').hide();
      
  }
  
);

