// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")
require("./custom.js")
require("../javascripts/main")
require("../javascripts/owl")
import('../styles/application.css');


// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//
$('.header_list').hover(
  function() {
        $(this).find('.child_list').show();
      
  }
  , function() {
        $(this).find('.child_list').hide();
      
  }
  
);
